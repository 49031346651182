export const SIDEBAR_ITEMS = [
  {
    id: "order",
    name: "order_no",
    icon: "sidebar/order",
    url: "/order",
    apiKey: "api::order",
    controllerKey: "order",
  },
]
