import { SIDEBAR_ITEMS } from "constants/SidebarItems"
import SidebarItem from "./SidebarItem"

const Sidebar = () => {
  return (
    <div className="w-sidebarWidth pt-10">
      <img src="/images/logo.svg" alt="logo" className="m-auto w-[106px]" />
      <div className="max-h-sidebarHeight overflow-scroll space-y-8 mt-10">
        {SIDEBAR_ITEMS?.map((item) => (
          <SidebarItem key={item.name} item={item} />
        ))}
      </div>
    </div>
  )
}

export default Sidebar
