import React from "react"
import { useTranslation } from "react-i18next"
import { Navigate, Route, Routes } from "react-router-dom"

const Order = React.lazy(() => import("pages/Order"))
const Theme = React.lazy(() => import("pages/Theme"))

const MainRoutes = () => {
  const { t } = useTranslation()

  return (
    <React.Suspense fallback={<span>{t("loading")}</span>}>
      <Routes>
        <Route path="/" element={<Navigate to="/order" />} />
        <Route path="/order" element={<Order />} />
        <Route path="/theme" element={<Theme />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </React.Suspense>
  )
}

export default MainRoutes
